import { useContext } from "react";
import HoverContext from '../context/HoverContext';
import SidebarContext from "../context/SidebarContext";
import Card from "../components/atoms/card_components/Card";
import { useState } from "react";

const Invoices = ({ step, setStep, achiveditems, setAchivedItems }) => {
  const { isMouseHover } = useContext(HoverContext);
  const { isSidebarOpen } = useContext(SidebarContext);

  const tHeaders = [
    { key: 'date', text: 'Date' },
    { key: 'amount', text: 'Amount',type:'currency' },
    { key: 'status', text: 'Status', type: 'badge' },
    { key: 'action_Download', text: 'Action', type: 'action' }
  ];

  const tDatas = [{
    date: '13/06/24',
    amount: 28000,
    status: 'New'
  }, {
    date: '13/05/24',
    amount: 28000,
    status: 'Past Due'
  }, {
    date: '13/04/24',
    amount: 28000,
    status: 'Paid'
  }, {
    date: '13/03/24',
    amount: 28000,
    status: 'Paid'
  }, {
    date: '13/02/24',
    amount: 28000,
    status: 'Paid'
  }, {
    date: '13/01/24',
    amount: 28000,
    status: 'Paid'
  }, {
    date: '13/12/23',
    amount: 28000,
    status: 'Paid'
  }, {
    date: '13/11/23',
    amount: 28000,
    status: 'Paid'
  }, {
    date: '13/10/23',
    amount: 28000,
    status: 'Paid'
  }, {
    date: '13/09/23',
    amount: 28000,
    status: 'Paid'
  }, {
    date: '13/08/23',
    amount: 28000,
    status: 'Paid'
  }, {
    date: '13/07/23',
    amount: 28000,
    status: 'Paid'
  }];

  tDatas.forEach(item => {
    item.date && (item.date = item.date.replace(/(\d{2})\/(\d{2})\/(\d{2})/, '20$3-$2-$1'));
  });
  tDatas.sort((a, b) => {
    if (a.date && b.date) {
      const dateA = new Date(b.date.split('/').reverse().join('-'));
      const dateB = new Date(a.date.split('/').reverse().join('-'));
      return dateA - dateB;
    }
    return false;
  });

  const badgeStyle = 'inline-flex gap-1 items-center rounded-full px-2 py-1 text-xs font-medium ring-1 ring-inset ';

  const badgeColors = [];
  badgeColors['green'] = 'text-[#15803d] bg-[#ccfce7] ring-green-600/20';
  badgeColors['blue'] = 'text-[#1d4ed8] bg-[#dbeafe] ring-blue-700/10';
  badgeColors['red'] = 'text-[#bd185d] bg-[#fcd7e3] ring-red-600/10';

  const badgeValues = [];
  badgeValues['Paid'] = 'green';
  badgeValues['New'] = 'blue';
  badgeValues['Past Due'] = 'red';

  const [currentPage, setpage] = useState(1);
  const totalPage = Math.ceil(tDatas.length / 25);
  const curDatas = tDatas.slice(25 * (currentPage - 1), 25 * currentPage - 1);

  const Row = ({ data }) => (
    <tr className="border-y-2 border-[#eee]">
      {tHeaders.map((h, i) => (
        <td className={"py-4"} key={i}>
          {
            data[h.key] ?
              <span className={h.type === "badge" ? badgeStyle + badgeColors[badgeValues[data[h.key]]] : ''}>
                {h.type === "badge" && (
                  <svg className={"w-[6px] h-[6px] text-center " + (badgeValues[data[h.key]] === "green" ? "fill-[#22c55e]" : badgeValues[data[h.key]] === "blue" ? "fill-[#3b82f6]" : "fill-[#ec4899]")} viewBox="0 0 6 6" aria-hidden="true"><circle cx="3" cy="3" r="3"></circle></svg>
                )
                }
                {
                  h.type === "currency" ?
                    "£" + data[h.key].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    :
                    data[h.key].replace(/(\d{4})-(\d{2})-(\d{2})/, '$3/$2/$1')
                }
              </span>
              :
              <>
                {
                  h.key.split('_')[1].split('@').map((action) => (
                    <a href="#" className="text-left text-sm sm:text-[13px] text-[#000050]"><u>{action}</u></a>
                  ))
                }
              </>
          }
        </td>
      ))}
    </tr>
  );

  return (
    <div className={"w-full flex flex-col flex-1 relative min-w-0 " + (isMouseHover && !isSidebarOpen ? 'ml-[68px]' : '')}>
      <div className="flex flex-col h-full relative overflow-y-auto" id="content-inner">
        <div className="p-4 bg-[#f1f4f9] grow pb-20 sm:pt-12">
          <div className="w-full h-full rounded-md border shadow-md bg-white font-medium">
            <div className="p-4 flex items-center justify-between w-full">
              <h1 className="text-dark text-[18px] font-inter">Portfolio Management</h1>
            </div>
            <div className="bg-[#eee] h-[2px] w-full"></div>
            <div className="w-full h-full overflow-scroll">
              <table className="w-full p-4 m-4">
                <colgroup>
                  {
                    tHeaders.map(h =>
                      <col width={parseInt(100 / tHeaders.length) + "%"} />
                    )
                  }
                </colgroup>
                <thead>
                  <tr className="text-left border-b-2 border-[#eee] font-inter pb-4">
                    {tHeaders.map((h, i) => (
                      <th key={i} className="py-4">{h.text}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {curDatas.map((data, i) => (
                    <Row data={data} key={i} />
                  ))}
                </tbody>
              </table>
              <div className="flex flex-row justify-center w-full">
                <div className="flex flex-row items-center justify-center gap-8 mb-4">
                  {
                    currentPage === 1 ?
                      <button className="bg-[#505050] text-white px-6 py-2 h-[40px] rounded-md w-full" disabled>Prev</button>
                      :
                      <button className="bg-[#000050] text-white px-6 py-2 h-[40px] rounded-md w-full" onClick={() => setpage(currentPage - 1)}>Prev</button>
                  }
                  <p className="text-center">{currentPage}</p>
                  {
                    currentPage === totalPage ?
                      <button className="bg-[#505050] text-white px-6 py-2 h-[40px] rounded-md w-full" disabled>Next</button>
                      :
                      <button className="bg-[#000050] text-white px-6 py-2 h-[40px] rounded-md w-full" onClick={() => setpage(currentPage + 1)} >Next</button>
                  }
                </div>
              </div>
            </div>
          </div>
        </div >
      </div>
    </div>
  )
}

export default Invoices;