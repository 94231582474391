import { useState, useContext } from "react";
import HoverContext from "../../context/HoverContext";
import SidebarContext from "../../context/SidebarContext";
import Card from "../../components/atoms/card_components/Card";

import BalanceImg from '../../assets/img/account-balance.png'
import CreditImg from '../../assets/img/credit-utilisation.png'

const Admin = () => {
  const { isMouseHover } = useContext(HoverContext);
  const { isSidebarOpen } = useContext(SidebarContext);

  const [tDatas, setTableDatas] = useState([{
    name: 'Apex Innovations Ltd',
    limit: 1000000,
    utilisation: 500000,
    status: 'Active',
  }, {
    name: 'Stellar Dynamics Ltd',
    limit: 1000000,
    utilisation: 50000,
    status: 'Active',
  }, {
    name: 'Quantum Ventures Ltd',
    limit: 1000000,
    utilisation: 900000,
    status: 'Frozen',
  }, {
    name: 'Horizon Enterprises Ltd',
    limit: 1000000,
    utilisation: 500000,
    status: 'Frozen',
  }, {
    name: 'Nova Solutions Ltd',
    limit: 1000000,
    utilisation: 500000,
    status: 'Frozen',
  }, {
    name: 'Zenith Holdings Ltd',
    limit: 1000000,
    utilisation: 500000,
    status: 'Frozen',
  }, {
    name: 'Pioneer Technologies Ltd',
    limit: 1000000,
    utilisation: 500000,
    status: 'Frozen',
  }, {
    name: 'Summit Industries Ltd',
    limit: 1000000,
    utilisation: 500000,
    status: 'Frozen',
  }, {
    name: 'Eclipse Marketing Ltd',
    limit: 1000000,
    utilisation: 500000,
    status: 'Frozen',
  }, {
    name: 'Vertex Capital Ltd',
    limit: 1000000,
    utilisation: 500000,
    status: 'Frozen',
  }])

  const tHeaders = [
    { key: 'name', text: 'Company Name', },
    { key: 'limit', text: 'Limit', type: 'currency' },
    { key: 'utilisation', text: 'Utilisation', type: 'complex' },
    { key: 'status', text: 'Status', type: 'badge' },
    { key: 'action', text: 'Action', type: 'action' }
  ];

  tDatas.forEach(item => {
    item.date && (item.date = item.date.replace(/(\d{2})\/(\d{2})\/(\d{2})/, '20$3-$2-$1'));
  });
  tDatas.sort((a, b) => {
    if (a.date && b.date) {
      const dateA = new Date(b.date.split('/').reverse().join('-'));
      const dateB = new Date(a.date.split('/').reverse().join('-'));
      return dateA - dateB;
    }
    return false;
  });

  const badgeStyle = 'inline-flex gap-1 items-center rounded-full px-2 py-1 text-xs font-medium ring-1 ring-inset ';
  const badgeColors = [];
  badgeColors['green'] = 'text-[#15803d] bg-[#ccfce7] ring-green-600/20';
  badgeColors['blue'] = 'text-[#1d4ed8] bg-[#dbeafe] ring-blue-700/10';
  badgeColors['red'] = 'text-[#bd185d] bg-[#fcd7e3] ring-red-600/10';
  badgeColors['yellow'] = 'text-[#854d0e] bg-[#fef9c3] ring-yellow-700/10';

  const badgeValues = [];
  badgeValues['Frozen'] = 'blue';
  badgeValues['Active'] = 'green';

  const [currentPage, setpage] = useState(1);
  const totalPage = Math.ceil(tDatas.length / 25);
  const curDatas = tDatas.slice(25 * (currentPage - 1), 25 * currentPage - 1);

  const Row = ({ data, tableDataChange }) => {
    const percent = parseInt(data['utilisation'] / data['limit'] * 100);
    return (<tr className="border-y-2 border-[#eee]">
      {tHeaders.map((h, i) => (
        <td className={"py-4"} key={i}>
          {
            data[h.key] ?
              <span className={h.type === "badge" ? badgeStyle + badgeColors[badgeValues[data[h.key]]] : ''}>
                {
                  h.type === "badge" && (
                    <svg className={"w-[6px] h-[6px] text-center " + (badgeValues[data[h.key]] === "green" ? "fill-[#22c55e]" : badgeValues[data[h.key]] === "blue" ? "fill-[#3b82f6]" : "fill-[#ec4899]")} viewBox="0 0 6 6" aria-hidden="true"><circle cx="3" cy="3" r="3"></circle></svg>
                  )
                }
                {
                  h.type === "currency" ?
                    "£" + data[h.key].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    :
                    h.type === "complex" ?
                      (
                        <>
                          {"£" + data[h.key].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                          <span className={"mx-2 " + badgeStyle + (percent <= 50 ? badgeColors['green'] : badgeColors['red'])}>{percent + "%"}</span>
                        </>
                      )
                      :
                      data[h.key].replace(/(\d{4})-(\d{2})-(\d{2})/, '£$3/$2/$1')
                }
              </span>
              :
              <div className="flex gap-4">
                {
                  data['status'] === "Active" ?
                    <a href="#" className="text-left text-sm sm:text-[13px] text-[#000050]" onClick={() => tableDataChange('Frozen')}><u>Freeze</u></a>
                    :
                    <a href="#" className="text-left text-sm sm:text-[13px] text-[#000050]" onClick={() => tableDataChange('Active')}><u>Activate</u></a>
                }
              </div>
          }
        </td>
      ))
      }
    </tr>
    )
  };

  const tableDataChange = (index, value) => {
    console.log(index, value)
    const newData = [...tDatas];
    newData[index] = { ...newData[index], status: value };
    setTableDatas(newData);
  }

  return (
    <div className={"w-full flex flex-col flex-1 relative min-w-0 " + (isMouseHover && !isSidebarOpen ? 'ml-[68px]' : '')}>
      <div className="flex flex-col h-full relative overflow-y-auto" id="content-inner">
        <div className="p-4 bg-[#f1f4f9] grow pb-20 sm:pt-12">
          <div className="sm:flex w-full gap-8">
            <div className="sm:w-1/3">
              <Card
                cardImage={BalanceImg}
                cardText={"Credit Overview"}
                value={"£2,000,000"}
                content={<p>{"Portfolio Health - "}{<span className={badgeStyle + badgeColors['green']}>Good - 95%</span>}</p>}
                actionText={"View Portfolio"}
                height={300}
              />
            </div>
            <div className="sm:w-1/3">
              <Card
                cardImage={CreditImg}
                cardText={"Credit Utilisation"}
                value={"£250,500"}
                content={"9% of Available Credit"}
                actionText={"View Utilisation Reports"}
                height={300}
              />
            </div>
            <div className="sm:w-1/3">
              <Card
                cardImage={CreditImg}
                cardText={"Facility Balance"}
                value={" £1,000,000"}
                content={<p>{"Fractional Reserve - "}{<span className={badgeStyle + badgeColors['yellow']}>50%</span>}</p>}
                actionText={"View Account Details"}
                height={300}
              />
            </div>
          </div>
          <div className="w-full h-full rounded-md border shadow-md bg-white font-medium gap-8 mt-6">
            <div className="p-4 flex items-center justify-between w-full">
              <h1 className="text-dark text-[18px] font-inter"> Portfolio Management</h1>
            </div>
            <div className="bg-[#eee] h-[2px] w-full"></div>
            <div className="w-full h-full overflow-scroll">
              <table className="w-full p-4 m-4">
                <colgroup>
                  {
                    tHeaders.map(h =>
                      <col width={parseInt(100 / tHeaders.length) + "%"} />
                    )
                  }
                </colgroup>
                <thead>
                  <tr className="text-left border-b-2 border-[#eee] font-inter pb-4">
                    {tHeaders.map((h, i) => (
                      <th key={i} className="py-4">{h.text}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {curDatas.map((data, i) => (
                    <Row data={data} key={i} tableDataChange={(val) => tableDataChange(i, val)} />
                  ))}
                </tbody>
              </table>
              <div className="flex flex-row justify-center w-full">
                <div className="flex flex-row items-center justify-center gap-8 mb-4">
                  {
                    currentPage === 1 ?
                      <button className="bg-[#505050] text-white px-6 py-2 h-[40px] rounded-md w-full" disabled>Prev</button>
                      :
                      <button className="bg-[#000050] text-white px-6 py-2 h-[40px] rounded-md w-full" onClick={() => setpage(currentPage - 1)}>Prev</button>
                  }
                  <p className="text-center">{currentPage}</p>
                  {
                    currentPage === totalPage ?
                      <button className="bg-[#505050] text-white px-6 py-2 h-[40px] rounded-md w-full" disabled>Next</button>
                      :
                      <button className="bg-[#000050] text-white px-6 py-2 h-[40px] rounded-md w-full" onClick={() => setpage(currentPage + 1)} >Next</button>
                  }
                </div>
              </div>
            </div>
          </div>
        </div >
      </div >
    </div >
  )
}

export default Admin;