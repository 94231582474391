import { Route, Routes, useNavigate } from "react-router-dom";

import Sidebar from './Sidebar.jsx';
import Board from './Board.jsx';
import Invoices from '../pages/Invoices.jsx';
import Admin from '../pages/admin/index.jsx';
import Login from '../pages/auth/login.jsx';
import { useAuth, useAuthDispatch } from "../context/AuthContext.js";
import { useGlobal, useGlobalDispatch } from "../context/GlobalContext.js";
import { useEffect } from "react";
import Cookies from 'js-cookie';
import { isJson } from "../common/utils.js";
import { useIdleTimer } from 'react-idle-timer'
import Loading from "./custom/Loading.jsx";

const Main = () => {
    const navigate = useNavigate();
    const auth = useAuth();
    const globalStates = useGlobal();
    const dispatch = useAuthDispatch();

    const handleOnIdle = (e) => {
        if(auth?.user) {
            dispatch({
                type: 'logout'
            });
            navigate('/login');
        }
    }

    const handleOnActive = (e) => {
        console.log('ja_active');
    }

    const handleOnAction = (e) => {
        console.log('ja_action');
    }

    useIdleTimer({
        timeout: 1000 * 60 * 30,
        onIdle: handleOnIdle,
        onActive: handleOnActive,
        onAction: handleOnAction,
        debounce: 500
      })

      
    useEffect(() => {
        const authCookieJSON = Cookies.get('auth');
        if(isJson(authCookieJSON)) {
            const authCoookie = JSON.parse(authCookieJSON);
            dispatch({
                type: 'verify',
                user: authCoookie
            })
        }
        else {
            navigate('/login');
        }

    }, []);

    useEffect(() => {
        console.log('ja_global', globalStates);
    }, [globalStates]);
    return (
        <div className='flex grow relative overflow-hidden'>
            {globalStates?.loading && <Loading />}
            {auth?.user && <Sidebar />}
            <Routes>
                <Route path="/" Component={Board} />
                <Route path="/invoices" Component={Invoices} />
                <Route path="/admin" Component={Admin} />
                <Route path="/login" Component={Login} />
            </Routes>
        </div>
    )
}

export default Main;