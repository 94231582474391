import logo from '../assets/img/sapi-white.png';
import { List } from "@phosphor-icons/react";
import SidebarContext from '../context/SidebarContext.js';
import { useContext } from 'react';

const Header = () => {
  const { isSidebarOpen, setIsSidebarOpen } = useContext(SidebarContext);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  }

  return (
    <>
      <div className='bg-nav pl-4 py-1 flex items-center'>
        <button className='md:hidden p-4' onClick={toggleSidebar}>
          <List color='white' size='24' />
        </button>

        <div className='md:block p-2 ml-2 hidden'>
          <img src={logo} alt='logo' className='md:h-[32px]' />
        </div>
        <div className='md:p-2 md:hidden w-[calc(100%-40px)] flex justify-center'>
          <img src={logo} alt='logo' className='h-7 -ml-16' />
        </div>
      </div>
    </>
  )
}

export default Header