import { createContext, useContext, useReducer } from 'react';
import Cookies from 'js-cookie';

export const AuthContext = createContext(null);
export const AuthDispatchContext = createContext(null);

export function AuthProvider({ children }) {
  const [auth, dispatch] = useReducer(
    authReducer,
    initialAuth
  );

  return (
    <AuthContext.Provider value={auth}>
      <AuthDispatchContext.Provider value={dispatch}>
        {children}
      </AuthDispatchContext.Provider>
    </AuthContext.Provider>
  );
}

export function useAuth() {
    return useContext(AuthContext);
}

export function useAuthDispatch() {
    return useContext(AuthDispatchContext);
}

function authReducer(auth, action) {
  switch (action.type) {
    case 'login': 
    {
      return {...auth,
        status: 'sent_code',
        email: action.email
      };
    }
    case 'verify': 
    {
        Cookies.set('auth', JSON.stringify(action.user), { expires: 7 });
        return {...auth,
          status: 'verified',
          user: action.user
        };
    }
    case 'logout': {
        Cookies.remove('auth');
        return null;
    }
    default: {
      throw Error('Unknown action: ' + action.type);
    }
  }
}
const initialAuth = null;