import React, { useState } from "react";

const Card = React.forwardRef(({ cardPage, cardType, cardImage, cardText, buttonText, value, content, actionText, focus, height, isSelfHeight }, ref) => {
  const scrollToTransactionPart = () => {
    if (focus && focus.current) {
      focus.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div
      className={
        `flex flex-col justify-between rounded-md border shadow-md bg-white w-full ${isSelfHeight ? '' : 'h-full'} mt-3 font-medium` +
        (height && ` min-h-[${height}px]`)
      }
      style={{ overflow: "autoflow" }}
      ref={ref}
    >
      {cardType === "file" ? (
        <>
          <div className="p-4 flex items-center justify-between w-full">
            <h1 className="text-dark text-[18px] font-inter">{cardText}</h1>
            <button className="bg-[#000050] text-[13px] text-white px-4 py-2 h-[40px] rounded-md">{buttonText}</button>
          </div>
          <div className="bg-[#eee] h-[2px] w-full"></div>
          <div className="w-full h-full flex items-center">
            <button className="bg-[#000050] text-white text-center h-[50px] m-auto my-2 px-4 py-2 rounded-md">
              {actionText}
            </button>
          </div>
        </>
      ) : (
        <>
          <div className="p-4 flex items-start gap-8 w-full">
            <img src={cardImage} alt="cardImage" className="w-[50px] my-auto" />
            <div className="flex flex-col">
              <h1 className="text-dark text-[18px] font-inter">{cardText}</h1>
              <p className="text-dark font-inter text-[32px] font-semibold">{value}</p>
              <p>{content}</p>
            </div>
          </div>
          <div className={`flex flex-col items-start bg-[#f3f4f5] ${cardPage === 'overview' ? 'mt-3': ''}`}>
            <p className="p-4 text-[#000050] cursor-pointer" onClick={() => scrollToTransactionPart()}>{actionText}</p>
          </div>
        </>
      )}
    </div>
  );
});

export default Card;
