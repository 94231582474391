import { useState, useRef, useEffect, useContext } from "react";
import Card from "../atoms/card_components/Card";

import BalanceImg from '../../assets/img/account-balance.png'
import CreditImg from '../../assets/img/credit-utilisation.png'
import { getAvailableBalance, getRepaymentInformation, getTransactionHistory, submitPasscode, uploadCSV } from "../../services/dashboardServices";
import { useAuthDispatch, useAuth } from "../../context/AuthContext.js";
import { useGlobal, useGlobalDispatch } from "../../context/GlobalContext.js";
import {
    Collapse
  } from "@material-tailwind/react";
  import toastr from "toastr";
import Loading from "../custom/Loading.jsx";
import TextInput from "../atoms/TextInput.jsx";
const Overview = () => {
    const PAGE_LENGTH = 50;
    const REPEAT_SECONDS = 1000 * 60 * 5;
    const transactionRef = useRef(null);
    const auth = useAuth();
    const globalStates = useGlobal();
    const globalDispatch = useGlobalDispatch();
    const [availableBalance, setAvailableBalance] = useState('');
    const [creditLimit, setCreditLimit] = useState('');
    const [creditUtilisationValue, setCreditUtilisationValue] = useState('');
    const [creditCurrency, setCreditCurrency] = useState('');
    const [accountNumber, setAccountNumber] = useState('');
    const [sortCode, setSortCode] = useState('');

    const [transactions, setTransactions] = useState([]);
    const [open, setOpen] = useState(false); 

    const [currentPage, setpage] = useState(1);
    const [totalPage, setTotalPage] = useState(1);
    const [passcode, setPasscode] = useState('');
    const [currTransactions, setCurrTransactions] = useState([]);
    const toggleOpen = () => setOpen((cur) => !cur);

    const inputFile = useRef(null) 

    const [showModal1, setShowModal1] = useState(false);
    const [showModal2, setShowModal2] = useState(false);
    const [showModalFailed, setShowModalFailed] = useState(false);

    const [instructionMerchantCount, setInstructionMerchantCount] = useState('');
    const [withdrawalReference, setWithdrawalReference] = useState('');
    const [instructionValue, setInstructionValue] = useState('');
    const [lastPaymentDate, setLastPaymentDate] = useState('');
    const currencySymbols = [
        {
            name: 'GBP',
            symbol: '£'
        },
        {
            name: 'EUR',
            symbol: '€'
        },
        {
            name: 'USD',
            symbol: '$'
        }
    ];
  const tHeaders = [
    { key: 'date', text: 'Date', },
    { key: 'description', text: 'Description' },
    { key: 'type', text: 'Type', type: 'badge' },
    { key: 'value', text: 'Amount', type: 'currency' }
  ];

  const handlePagination = (currentPageTemp) => {
    setpage(currentPageTemp)
    setCurrTransactions(transactions.slice(PAGE_LENGTH * (currentPageTemp - 1), PAGE_LENGTH * currentPageTemp - 1));
  }

  useEffect(() => {
    if(auth?.user) {
        handleGetData();
        const { sapiReference } = auth.user;
        getRepaymentInformation(sapiReference).then(res => {
            console.log('ja_repayment_res', res);
            if(res.status === "successful") {
                setAccountNumber(res.sapiAccountNumber);
                setSortCode(res.sapiSortCode);
            }
        }).catch(err => console.log(err));
    }
  }, [auth]);

  useEffect(() => {
    if(auth?.user) {
        const countdownTimer = setInterval(() => {
            handleGetData();
        }, REPEAT_SECONDS);
        return () => clearInterval(countdownTimer);
    }
  }, [auth]);

  const formatter = new Intl.DateTimeFormat("en-GB", {
    year: 'numeric', month: '2-digit',day: '2-digit'
  });

  const goBack = () => {
    setShowModal1(false);
    }
  const handleOpenFileBrowser = () => {
    inputFile.current.click();
  };

  const handleSumbitPasscode = () => {
    if(auth?.user) {
        const { sapiReference } = auth.user;
        globalDispatch({
            type: 'loading',
            status: true
        })
        submitPasscode(passcode, withdrawalReference, sapiReference)
        .then(res => {
            setPasscode('');
            globalDispatch({
                type: 'loading',
                status: false
            })
            setShowModal1(false);
            if(res?.status == 'successful') {
                setShowModal2(true);
                handleGetData();
            }
            else {
                setShowModalFailed(true);
            }
        });
    }
  }

  const onChangeFile = (e) => {
    const file = e.target.files[0];
    if (file && file.type === "text/csv") {
        console.log('valid csv', file);
        if(auth?.user) {
            globalDispatch({
                type: 'loading',
                status: true
            });
            const { sapiReference } = auth.user;
            uploadCSV(file, sapiReference)
            .then(res => {
                globalDispatch({
                    type: 'loading',
                    status: false
                });
                console.log('ja_res_upload', res);
                if(res?.status == 'successful') {
                    const {sapiWithdrawalReference, sapiInstructionMerchantCount, sapiInstructionValue} = res;
                    setWithdrawalReference(sapiWithdrawalReference);
                    setInstructionMerchantCount(sapiInstructionMerchantCount);
                    setInstructionValue(sapiInstructionValue);
                    setShowModal1(true);
                    toastr.success('CSV uploaded successfully');
                }
                else {
                    setShowModalFailed(true);
                    toastr.error('Failed to upload the CSV');
                }
            });
        }
    }
    else {
        toastr.error('This file is not a CSV.')
    }
  }

  const handleGetData = () => {
    console.log('ja_getdata');
    const { sapiReference } = auth?.user;
    if(sapiReference) {
        getAvailableBalance(sapiReference).then(res => {
            if(res.status === "successful") {
                setAvailableBalance(res.sapiAvailableBalance);
                setCreditLimit(res.sapiCreditLimit);
                setCreditUtilisationValue(res.sapiCreditUtilisationValue);
                setCreditCurrency(currencySymbols.find(currency => currency.name === res.sapiCreditCurrency)?.symbol ?? '$');
            }
        }).catch(err => console.log(err));

        getTransactionHistory(sapiReference).then(res => {
            if(res.status === "successful" && res.sapiWithdrawals && res.sapiWithdrawals.length) {
                const sapiWithdrawals = res.sapiWithdrawals;
                const sapiRepayments = res.sapiRepayments;
                let transactionsTemp = [];
                transactionsTemp = sapiWithdrawals.map(withdrawItem => {
                    return {
                        date: formatter.format(Date.parse(withdrawItem.createdAt)),
                        description: 'New Instruction - ' + withdrawItem.merchantCount + ' Merchant' + (withdrawItem.merchantCount == 1 ? '' : 's'),
                        type : 'Withdrawal',
                        value : withdrawItem.value
                    }
                });
                if(sapiRepayments && sapiRepayments.length) {
                    transactionsTemp = [
                        ...transactionsTemp,
                        ...(sapiRepayments.map(repaymentItem => {
                            return {
                                date : formatter.format(Date.parse(repaymentItem.createdAt)),
                                description: 'Repayment via BACS',
                                type : 'Repayment',
                                value : repaymentItem.value
                            }
                        }))
                    ];
                    sapiRepayments.sort((a, b) => {
                        if (a.createdAt && b.createdAt) {
                          const dateA = new Date(a.createdAt);
                          const dateB = new Date(b.createdAt);
                          return dateB - dateA;
                        }
                        return false;
                    });
                    console.log('ja_lastrepayment', formatter.format(Date.parse(sapiRepayments[0].createdAt)));
                    setLastPaymentDate(formatter.format(Date.parse(sapiRepayments[0].createdAt)))
                }
                
                transactionsTemp.sort((a, b) => {
                    if (a.date && b.date) {
                      const dateA = new Date(b.date.split('/').reverse().join('-'));
                      const dateB = new Date(a.date.split('/').reverse().join('-'));
                      return dateA - dateB;
                    }
                    return false;
                });
                setTransactions(transactionsTemp);
                console.log('ja_totalpage', Math.ceil(transactionsTemp.length / PAGE_LENGTH))
                setTotalPage(Math.ceil(transactionsTemp.length / PAGE_LENGTH));
                setCurrTransactions(transactionsTemp.slice(PAGE_LENGTH * (currentPage - 1), PAGE_LENGTH * currentPage - 1));
            }
        }).catch(err => console.log(err));
    }
  }
  

  const badgeStyle = 'inline-flex gap-1 items-center rounded-full px-2 py-1 text-xs font-medium ring-1 ring-inset ';
  const badgeColors = [];
  badgeColors['green'] = 'text-[#15803d] bg-[#ccfce7] ring-green-600/20';
  badgeColors['blue'] = 'text-[#1d4ed8] bg-[#dbeafe] ring-blue-700/10';
  badgeColors['red'] = 'text-[#bd185d] bg-[#fcd7e3] ring-red-600/10';

  const badgeValues = [];
  badgeValues['Repayment'] = 'green';
  badgeValues['Withdrawal'] = 'blue';

  


  const Row = ({ data }) => (
    <tr className="border-y-2 border-[#eee]">
      {tHeaders.map((h, i) => (
        <td className={"py-4"} key={i}>
          {
            data[h.key] ?
              <span className={h.type === "badge" ? badgeStyle + badgeColors[badgeValues[data[h.key]]] : ''}>
                {h.type === "badge" && (
                  <svg className={"w-[6px] h-[6px] text-center " + (badgeValues[data[h.key]] === "green" ? "fill-[#22c55e]" : badgeValues[data[h.key]] === "blue" ? "fill-[#3b82f6]" : "fill-[#ec4899]")} viewBox="0 0 6 6" aria-hidden="true"><circle cx="3" cy="3" r="3"></circle></svg>
                )
                }
                {
                  h.type === "currency" ?
                    creditCurrency + data[h.key].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    :
                    data[h.key].replace(/(\d{4})-(\d{2})-(\d{2})/, '$3/$2/$1')
                }
              </span>
              :
              <>
                {
                  h.key.split('_')[1].split('@').map((action, i) => (
                    <a href="#" key={i} className="text-left text-sm sm:text-[13px] text-[#000050]"><u>{action}</u></a>
                  ))
                }
              </>
          }
        </td>
      ))}
    </tr>
  );

  return (
    <div className="p-4 bg-[#f1f4f9] grow pb-20 sm:pt-12">
      <div className="sm:flex w-full gap-8">
        <div className="sm:w-1/3">
          <Card
            cardPage={'overview'}
            isSelfHeight={true}
            cardImage={BalanceImg}
            cardText={"Available Balance"}
            value={availableBalance ? (creditCurrency + Number(availableBalance).toFixed(2).toLocaleString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")) : '-' }
            content={"Credit Limit - " + (creditLimit ? creditCurrency +  Number(creditLimit).toFixed(2).toLocaleString().replace(/\B(?=(\d{3})+(?!\d))/g, ","): '')}
            actionText={"View Transactions"}
            focus={transactionRef}
          />
        </div>
        <div className="sm:w-1/3">
            <div className={"flex flex-col justify-start rounded-md border shadow-md bg-white w-full mt-3 font-medium"} style={{ overflow: "autoflow" }}>
                <div className="p-4 flex items-start gap-8 w-full">
                    <img src={CreditImg} alt="cardImage" className="w-[50px] my-auto" />
                    <div className="flex flex-col">
                        <h1 className="text-dark text-[18px] font-inter">{"Credit Utilisation"}</h1>
                        <p className="text-dark font-inter text-[32px] font-semibold">{creditUtilisationValue ? creditCurrency + Number(creditUtilisationValue).toFixed(2).toLocaleString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '-'}</p>
                        <p>Last Repayment - {lastPaymentDate}</p>
                    </div>
                </div>
                <div className="flex flex-col items-start bg-[#f3f4f5] mt-3">
                    <p className="p-4 text-[#000050] cursor-pointer" onClick={toggleOpen}>{"View Repayment Information"}</p>
                    <Collapse open={open}>
                        <div className="p-4 bg-white">
                            <b>Payment Reference:</b> {auth?.user.sapiReference ?? ''}<br/>
                            <b>Account Name:</b> SAPI Group Limited<br/>
                            <b>Account Number:</b> {accountNumber}<br/>
                            <b>Account Sort Code:</b> {sortCode}
                        </div>
                    </Collapse>
                </div>
            </div>
        </div>
        <div className="sm:w-1/3">
            <div
                className='flex flex-col justify-between rounded-md border shadow-md bg-white w-full mt-3 font-medium h-[201px]'
                style={{ overflow: "autoflow" }}
            >
                <div className="p-4 flex items-center justify-between w-full">
                    <h1 className="text-dark text-[18px] font-inter">{"New Instruction"}</h1>
                    <button className="bg-[#000050] text-[13px] text-white px-4 py-2 h-[40px] rounded-md hidden">{'View API Keys'}</button>
                </div>
                <div className="bg-[#eee] h-[2px] w-full"></div>
                <div className="w-full h-full flex items-center">
                    <input type='file' id='file' ref={inputFile} style={{display: 'none'}} onChange={onChangeFile}/>
                    <button className="bg-[#000050] text-white text-center h-[50px] m-auto my-2 px-4 py-2 rounded-md" onClick={handleOpenFileBrowser}>
                        {"Upload New Instruction"}
                    </button>
                </div>
            </div>
        </div>
      </div>
      <div className="w-full h-full rounded-md border shadow-md bg-white font-medium gap-8 mt-6" ref={transactionRef}>
        <div className="p-4 flex items-center justify-between w-full">
          <h1 className="text-dark text-[18px] font-inter">Transaction History</h1>
        </div>
        <div className="bg-[#eee] h-[2px] w-full"></div>
        <div className="w-full h-full overflow-scroll">
          <table className="w-full p-4 m-4">
            <colgroup>
              {
                tHeaders.map((h, i) =>
                  <col key={i} width={parseInt(100 / tHeaders.length) + "%"} />
                )
              }
            </colgroup>
            <thead>
              <tr className="text-left border-b-2 border-[#eee] font-inter pb-4">
                {tHeaders.map((h, i) => (
                  <th key={i} className="py-4">{h.text}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {currTransactions.map((data, i) => (
                <Row data={data} key={i} />
              ))}
            </tbody>
          </table>
          <div className="flex flex-row justify-center w-full">
            <div className="flex flex-row items-center justify-center gap-8 mb-4">
              {
                currentPage === 1 ?
                  <button className="bg-[#505050] text-white px-6 py-2 h-[40px] rounded-md w-full" disabled>Prev</button>
                  :
                  <button className="bg-[#000050] text-white px-6 py-2 h-[40px] rounded-md w-full" onClick={() => handlePagination(currentPage - 1)}>Prev</button>
              }
              <p className="text-center">{currentPage}</p>
              {
                currentPage === totalPage ?
                  <button className="bg-[#505050] text-white px-6 py-2 h-[40px] rounded-md w-full" disabled>Next</button>
                  :
                  <button className="bg-[#000050] text-white px-6 py-2 h-[40px] rounded-md w-full" onClick={() => handlePagination(currentPage + 1)} >Next</button>
              }
            </div>
          </div>
        </div>
      </div>

      {showModal1 && (
        <>
          <div
            className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
          >
            <div className="relative w-auto my-6 mx-auto max-w-lg">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-center justify-center pt-12 rounded-t text-center">
                  <h3 className="text-3xl font-semibold w-full">
                    New Withdrawal<br/>Instruction
                  </h3>
                  <button
                    className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => setShowModal1(false)}
                  >
                    <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                      ×
                    </span>
                  </button>
                </div>
                {/*body*/}
                <div className="relative flex-auto px-12">
                  <p className="my-4 text-blueGray-500 text-lg leading-relaxed text-center text-sm">
                    Your new withdrawal instruction containing {instructionMerchantCount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} Merchants & requesting {creditCurrency}{instructionValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} has been received.<br /><br/>
                    We have sent a secure 6-digit passcode to your email address.<br/>
                    Please ensure you check your spam/junk folder.
                  </p>
                  <input type="text" name="passcode" id="passcode" className="mt-2 text-sm bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Enter your passcode" value={passcode} onChange={e => setPasscode(e.target.value)}/>
                </div>
                <div>
                    {/* <input type="text" placeholder="Enter your passcord" /> */}
                    
                </div>
                {/*footer*/}
                <div className="flex flex-col items-center justify-end p-6 rounded-b">
                  
                  <button
                    className="bg-[#000050] text-white font-bold uppercase text-sm px-12 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => handleSumbitPasscode()}
                  >
                    Submit
                  </button>
                  <button className="mt-4 text-[13px] underline" onClick={() => goBack()}>Back</button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      )}

        {showModal2 && (
        <>
          <div
            className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
          >
            <div className="relative w-auto my-6 mx-auto max-w-lg">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-center justify-center pt-12 rounded-t text-center">
                  <h3 className="text-3xl font-semibold w-full">
                    New Withdrawal<br/>Instruction
                  </h3>
                  <button
                    className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => setShowModal1(false)}
                  >
                    <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                      ×
                    </span>
                  </button>
                </div>
                {/*body*/}
                <div className="relative flex-auto px-12">
                  <p className="my-4 text-blueGray-500 text-lg leading-relaxed text-center text-sm">
                    Your new withdrawal instruction containing {instructionMerchantCount.replace(/\B(?=(\d{3})+(?!\d))/g, ",")} Merchants & requesting {creditCurrency}{instructionValue.replace(/\B(?=(\d{3})+(?!\d))/g, ",")} has been accepted.<br /><br/>
                    Your funds will be disbursed within the next 2-4 hours.<br/>
                    <br/>Business Operational Times:<br/>
                    Monday to Friday<br/>
                    9am to 5pm
                  </p>
                </div>
                <div>
                    {/* <input type="text" placeholder="Enter your passcord" /> */}
                    
                </div>
                {/*footer*/}
                <div className="flex flex-col items-center justify-end p-6 rounded-b">
                  
                  <button
                    className="bg-[#000050] text-white font-bold uppercase text-sm px-12 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => setShowModal2(false)}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      )}

{showModalFailed && (
        <>
          <div
            className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
          >
            <div className="relative w-auto my-6 mx-auto max-w-lg">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-center justify-center pt-12 rounded-t text-center">
                  <h3 className="text-3xl font-semibold w-full">
                    Withdrawal Instruction<br/>Rejected
                  </h3>
                  <button
                    className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => setShowModalFailed(false)}
                  >
                    <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                      ×
                    </span>
                  </button>
                </div>
                {/*body*/}
                <div className="relative flex-auto px-12">
                  <p className="my-4 text-blueGray-500 text-lg leading-relaxed text-center text-sm">
                    Your new withdrawal instruction has been rejected.<br /><br/>
                    Your available balance is:<br/>
                    {creditCurrency}{availableBalance.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}<br/>
                    <br/>Please contact our Partnerships Team if you need further support:<br/>
                    <a href="mailto:partners@sapi.com">partners@sapi.com</a>
                  </p>
                </div>
                {/*footer*/}
                <div className="flex flex-col items-center justify-end p-6 rounded-b">
                  
                  <button
                    className="bg-[#000050] text-white font-bold uppercase text-sm px-12 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => setShowModalFailed(false)}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      )}
    </div >
  )
}

export default Overview;