import { useLocation, useNavigate } from "react-router-dom"
const Item = ({ Icon, name, url, active, state, status, expanded, compo }) => {
    const location = useLocation();
    const navigate = useNavigate();
    console.log(location);

    return (
        <button disabled={state} onClick={() => navigate(url)} className={'w-full flex justify-between items-center px-6 py-3 hover:bg-nav-hover gap-2 cursor-pointer ' + (active ? 'bg-nav-active' : '') + (location.pathname === url ? ' bg-[#26266a]' : '')}>
            <div className="flex gap-4">
                <Icon color='white' weight="bold" size={20} />
                {
                    expanded && (
                        <p className='text-white font-bold text-sm'>{name}</p>
                    )
                }
            </div>
        </button>
    )
}

export default Item;