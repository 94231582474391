import { useContext, useState } from "react";
import Section from "./navbar/Section.jsx";
import { ArrowsLeftRight, X, House, Calculator } from "@phosphor-icons/react";
import SidebarContext from "../context/SidebarContext";
import HoverContext from '../context/HoverContext';
import { useLocation } from "react-router-dom";

const Sidebar = ({ step, setStep }) => {
  const { isSidebarOpen, setIsSidebarOpen } = useContext(SidebarContext);
  const { isMouseHover, setIsMouseHover } = useContext(HoverContext);
  const location = useLocation();
  console.log(location);
  const isAdmin = location.pathname.includes('admin');


  const mainSection = {
    name: 'Partner Dashboard',
    items: [
      {
        icon: House,
        name: 'Dashboard',
        url: '/',
        state: false,
        compo: 'menu--1',
      },
    //   {
    //     icon: Calculator,
    //     name: 'Invoices',
    //     url: '/invoices',
    //     state: false,
    //     compo: 'menu--2',
    //   }
    ]
  }

  const adminMainSection = {
    name: 'Portfolio Management',
    items: [
      {
        icon: House,
        name: 'Dashboard',
        url: '/admin',
        state: false,
        compo: 'menu--1',
      },
    ]
  }

  const mouseEnter = () => {
    setIsMouseHover(true)
  }

  const mouseLeave = () => {
    setIsMouseHover(false)
  }

  const clickSidebarOpen = () => {
    if (isSidebarOpen) setIsMouseHover(false);
    setIsSidebarOpen(!isSidebarOpen)
  }

  const clickX = () => {
    setIsMouseHover(false)
    setIsSidebarOpen(false)
  }

  return (
    <div className={'bg-nav border-t border-[#1a1a62] max-h-full overflow-hidden overflow-y-auto top-0 z-[99] md:block transition-all duration-300 md:transition-none ' + (isSidebarOpen || isMouseHover ? 'w-[18.75rem] ' : 'w-[76px] -left-[18.75rem] ') + ('md:static fixed h-screen')} onMouseEnter={mouseEnter} onMouseLeave={mouseLeave}>
      <div className="flex justify-between items-center px-4 py-4 font-bold">
        {
          (isMouseHover || isSidebarOpen) && (
            <h5 className="text-white text-xl font-medium">{isAdmin ? adminMainSection.name : mainSection.name}</h5>
          )
        }
        <div className="rounded-full bg-nav-active p-2 hover:cursor-pointer hover:bg-[#5d5da3]">
          <ArrowsLeftRight className="md:block hidden" color='white' weight="bold" size={20} onClick={clickSidebarOpen} />
          <X className="md:hidden block" color='white' weight="bold" size={20} onClick={clickX} />
        </div>
      </div>

      <Section
        expanded={(isSidebarOpen === true || isMouseHover === true)}
        name={isAdmin ? adminMainSection.name : mainSection.name}
        items={isAdmin ? adminMainSection.items : mainSection.items}
      />

    </div>
  )
}

export default Sidebar;