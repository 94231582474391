import { API_HOST_NAME } from "../common/constants";

export const login = (sapiEmail, sapiIp) => {
    return fetch(`${API_HOST_NAME}/users/welcome`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            sapiEmail,
            sapiIp
        })
    }).then(response => response.json())
    .then(data => data)
    .catch(err => err)
}

export const verify = (sapiEmail, sapiCodeValue) => {
    return fetch(`${API_HOST_NAME}/users/verify`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            sapiEmail,
            sapiCodeValue
        })
    }).then(response => response.json())
    .then(data => data)
    .catch(err => err)
}