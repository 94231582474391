import Dashboard from "./layouts/Dashboard.jsx";
import './assets/css/font-awesome/css/font-awesome.min.css'
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";

function App() {
  return (
    <Router>
      <Dashboard />
    </Router>
  )
}

export default App;
