import Header from '../components/Header.jsx';
import Main from '../components/Main.jsx';
import SidebarContext from '../context/SidebarContext';
import HoverContext from '../context/HoverContext';
import { useState, useContext } from 'react';
import { AuthProvider } from '../context/AuthContext.js';
import { GlobalProvider } from '../context/GlobalContext.js';

const Dashboard = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [isMouseHover, setIsMouseHover] = useState(false);


  return (
    <div className='h-screen flex flex-col'>
    <GlobalProvider>
        <AuthProvider>
            <HoverContext.Provider value={{ isMouseHover, setIsMouseHover }}>
                <SidebarContext.Provider value={{ isSidebarOpen, setIsSidebarOpen }}>
                <Header />
                <Main />
                </SidebarContext.Provider>
            </HoverContext.Provider>
        </AuthProvider>
      </GlobalProvider>
    </div>
  )
}

export default Dashboard;