import { useContext } from "react";
import Overview from "./main/Overview";
import HoverContext from '../context/HoverContext';
import SidebarContext from "../context/SidebarContext";

const Board = ({ step, setStep, achiveditems, setAchivedItems }) => {
  const { isMouseHover } = useContext(HoverContext);
  const { isSidebarOpen } = useContext(SidebarContext);

  return (
    <div className={"w-full flex flex-col flex-1 relative min-w-0 " + (isMouseHover && !isSidebarOpen ? 'ml-[68px]' : '')}>
      <div className="flex flex-col h-full relative overflow-y-auto" id="content-inner">
        <Overview
          step={step}
          setStep={setStep}
          achiveditems={achiveditems}
          setAchivedItems={setAchivedItems}
        />
      </div>
    </div>
  )
}

export default Board;