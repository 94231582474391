import { API_HOST_NAME } from "../common/constants";

export const getAvailableBalance = (sapiReference) => {
    return fetch(`${API_HOST_NAME}/users/available-balance`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            sapiReference
        })
    }).then(response => response.json())
    .then(data => data)
    .catch(err => err)
}

export const getTransactionHistory = (sapiReference) => {
    return fetch(`${API_HOST_NAME}/users/transaction-history`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            sapiReference
        })
    }).then(response => response.json())
    .then(data => data)
    .catch(err => err)
}

export const getRepaymentInformation = (sapiReference) => {
    return fetch(`${API_HOST_NAME}/users/repayment-information`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            sapiReference
        })
    }).then(response => response.json())
    .then(data => data)
    .catch(err => err)
}

export const uploadCSV = (file, sapiReference) => {
    const formData = new FormData();
    formData.append('sapiInstructionFile', file);
    formData.append('sapiReference', sapiReference);
    return fetch(`${API_HOST_NAME}/users/new-instruction`, {
        method: "POST",
        headers: {
            "Accept": "application/json"
        },
        body: formData
    }).then(response => response.json())
    .then(data => data)
    .catch(err => err)
}

export const submitPasscode = (sapiCodeValue, sapiWithdrawalReference, sapiReference) => {
    const formData = new FormData();
    formData.append('sapiCodeValue', sapiCodeValue);
    formData.append('sapiWithdrawalReference', sapiWithdrawalReference);
    formData.append('sapiReference', sapiReference);

    return fetch(`${API_HOST_NAME}/users/new-instruction-confirmation`, {
        method: "POST",
        headers: {
            "Accept": "application/json"
        },
        body: formData
    }).then(response => response.json())
    .then(data => data)
    .catch(err => err)
}