import { DotsThree } from "@phosphor-icons/react";
import Item from './Item';
import SapiImg from '../../assets/img/sapi-white.png'
import { useAuth, useAuthDispatch } from "../../context/AuthContext";
import { useNavigate } from "react-router-dom";

const Section = ({ items, name, expanded, step, setStep }) => {
    const dispatch = useAuthDispatch();
    const navigate = useNavigate();
    const handleLogOut = () => {
    dispatch({
        type: 'logout'
    });
    navigate('/login');
 }
  return (
    <div className="">
      {
        items.map((element, idx) => (
          <Item active={element.active} key={idx} Icon={element.icon} expanded={expanded} {...element} />
        ))
      }

      <div className={`${expanded ? 'w-[18.75rem] ' : 'w-[68px]'} 3xl:h-auto 3xl:mt-20 md:h-48 h-36 mt-6 text-white flex flex-col items-center relative 3xl:absolute 3xl:bottom-6 border-red-500`}>
        {expanded &&
          <>
            <a className="md:mb-4 mb-2 bg-white btn-primary text-[#000050] py-2 px-4 rounded font-[500] text-sm w-48 text-center cursor-pointer" href="mailto:partners@sapi.com?subject=SAPI Grow Partner Support Request">Customer Support</a>
            <button className="flex justify-center items-center md:mb-12 mb-6 bg-white btn-primary text-[#000050] py-2 px-4 rounded font-[500] text-sm w-48" onClick={() => handleLogOut()}>
                <svg className="mr-2 w-4 h-4 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 16">
                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 8h11m0 0L8 4m4 4-4 4m4-11h3a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2h-3"/>
                </svg>
                Logout
            </button>
            <p>Powered By</p>
          </>}
        <img src={SapiImg} className={`${expanded ? 'h-5' : 'h-4'} w-auto`} alt="" />
      </div>
    </div>
  )
}

export default Section