import { useContext, useEffect} from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {internalIpV6, internalIpV4} from 'internal-ip';
import axios from 'axios'
import { API_HOST_NAME } from '../../common/constants.js'
import logo from '../../assets/img/sapi-black.png';
import { login, verify } from "../../services/authService.js";
import { useAuthDispatch, useAuth } from "../../context/AuthContext.js";
import toastr from 'toastr';

const Login = ({ }) => {
    const navigate = useNavigate();

const dispatch = useAuthDispatch();
const auth = useAuth();
const [email, setEmail] = useState('');
const [code, setCode] = useState('');
const [loading, setLoadng] = useState(false);

useEffect(() => {
    if(auth?.user) {
        navigate('/');
    }
}, [auth])

const handleLogin = (event) => {
    event.preventDefault();
    setLoadng(true);
    fetch('https://api.ipify.org?format=json')
      .then(res => res.json())
      .then(data => {
        if(data && data.ip) {
            login(email, data.ip).then(res => {
                if(res && res.status === 'successful') {
                    dispatch({
                        type: 'login',
                        email,
                        stauts: 'success'
                    })
                }
                else {
                  loginFailedAlert();
                }
                setLoadng(false);
            });
        }
      })
      .catch(error => {
        console.log(error);
        setLoadng(false);
        loginFailedAlert();
      }
    ).finally(() => {
    });
};


const handleVerify = (event) => {
    event.preventDefault();
    setLoadng(true);
    verify(auth.email, code)
    .then(res => {
        if(res && res.status === 'successful') {
            dispatch({
                type: 'verify',
                user: res
            });
            navigate('/');
        }
        else {
            verifyFailedAlert();
        }
    }).catch(err => {
        verifyFailedAlert();
        console.log(err);
    }).finally(() => {
        setLoadng(false);
    });
};

const loginFailedAlert = () => {
    toastr.error('Login Failed');
}


const verifyFailedAlert = () => {
    toastr.error('Verification Failed');
}

const goBack = () => {
    dispatch({
        type: 'logout'
    });
}

  return (
    <div className="login-container w-full">
        {
            loading && 
            <div className="loading-container w-full h-full flex justify-center items-center fixed opacity-30">
                <div
                    className="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] text-primary motion-reduce:animate-[spin_1.5s_linear_infinite]"
                role="status">
                    <span
                        className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
                    >Loading...</span>
                </div>
            </div>
        }
        
        
        <section className="bg-gray-50 dark:bg-gray-900 w-full">
            <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
                <div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
                    <div className="p-6 space-y-4 md:space-y-6 sm:p-8 flex flex-col items-center">
                        <img src={logo} alt='logo' className='md:h-[32px]' />
                        <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
                            Welcome back!
                        </h1>
                        {
                            !auth ?
                            <>
                                <p className="text-center text-[13px]">Let’s get you logged into your SAPI Partner Dashboard</p>
                                <form className="space-y-4 md:space-y-6 w-full" onSubmit={handleLogin}>
                                    <div>
                                        <input type="email" name="email" id="email" className="text-sm bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Enter your email address" required value={email} onChange={e => setEmail(e.target.value)}/>
                                    </div>
                                    <button type="submit" className="w-full text-white bg-nav hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800">Submit</button>
                                </form>
                            </>
                            :
                            <>
                                <p className="text-center text-[13px]">We have sent a secure 6-digit passcode to your email address.
                                <br/>Please ensure your check your spam/junk folder</p>
                                <form className="w-full space-y-4 md:space-y-6 text-center" onSubmit={handleVerify}>
                                    <div>
                                        <input type="text" name="code" id="code" className="text-sm bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Enter your 6-digit passcode" required value={code} onChange={e => setCode(e.target.value)}/>
                                    </div>
                                    <button type="submit" className="w-full text-white bg-nav hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800">Submit</button>
                                    <button className="text-[13px] underline" onClick={() => goBack()}>Go Back</button>
                                </form>
                            </>
                        }
                        
                    </div>
                </div>
            </div>
        </section>
    </div>
  )
}

export default Login;