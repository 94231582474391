import { createContext, useContext, useReducer } from 'react';

const GlobalContext = createContext(null);

const GlobalDispatchContext = createContext(null);

export function GlobalProvider({ children }) {
  const [globalStates, dispatch] = useReducer(
    globalReducer,
    initialGlobalStates
  );

  return (
    <GlobalContext.Provider value={globalStates}>
      <GlobalDispatchContext.Provider value={dispatch}>
        {children}
      </GlobalDispatchContext.Provider>
    </GlobalContext.Provider>
  );
}

export function useGlobal() {
  return useContext(GlobalContext);
}

export function useGlobalDispatch() {
  return useContext(GlobalDispatchContext);
}

function globalReducer(globalStates, action) {
  switch (action.type) {
    case 'update': {
      return {...globalStates, ...action.values};
    }
    case 'loading': {
        return {...globalStates, loading: action.status};
      }
    default: {
      throw Error('Unknown action: ' + action.type);
    }
  }
}

const initialGlobalStates = {
    loading: false
};
